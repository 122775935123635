import React, { useState, FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PurpleAccentHero from "../components/PurpleAccentHero"
import Banner from "../components/Banner"
import Button from "../components/Button"
import { TextField } from "@material-ui/core"
import { API_URL } from "../utils/constants"
import axios from "axios"
import CookiesSnackbar from "../components/CookiesSnackbar"
import {makeStyles} from "@material-ui/styles";
import DiscoverMoreBanner from "../components/DiscoverMoreBanner"

const useStyles = makeStyles({
    titleHeadingGradientWrapper: {
        width: "100%",
        height: "100%",
        background: "linear-gradient(110.11deg, #FFFFFF 38%, rgba(255, 255, 255, 0) 67.89%)",
        "@media(max-width: 959px)": {
            background: "rgba(255, 255, 255, 0) 67.89%)"
        }
    },
    titleHeadingText: {
        marginLeft: 100,
        maxWidth: 360,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        color: "rgba(0, 0, 0, 0.8)",
        letterSpacing: "0.25px",
        "@media (max-width: 959px)": {
            width: "100%",
            maxWidth: "100%",
            marginLeft: 0,
            padding: 24
        }
    },
    body: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 48,
        padding: 24
    },
    bodyContent: {
        width: 600
    },
    bodyTitle: {
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 32,
        lineHeight: 4,
        letterSpacing: 0.25,
        marginBottom: "16px !important"
    },
    feedback: {
        fontFamily: "Ubuntu",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 24,
        lineHeight: 1.25,
        letterSpacing: 0.25,
        marginBottom: "16px !important"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    }
})

const RequestDemoPage: FC = () => {
    const classes = useStyles()
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState(false)
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [message, setMessage] = useState("")
    const [submitted, setSubmitted] = useState(false)

    const data = useStaticQuery(graphql`
        query {
            desktopHeroImage: file(relativePath: { eq: "demo/demo-hero-desktop.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 2160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            mobileHeroImage: file(relativePath: { eq: "demo/demo-hero-mobile.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            desktopBannerImage: file(relativePath: { eq: "banner-desktop.png" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 2160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            mobileBannerImage: file(relativePath: { eq: "banner-mobile.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 2160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const nameChange = (event) => {
        event.preventDefault()
        setName(event.target.value)
    }
    const emailChange = (event) => {
        event.preventDefault()
        setEmail(event.target.value)
    }
    const messageChange = (event) => {
        event.preventDefault()
        setMessage(event.target.value)
    }

    const onSubmit = async (event) => {
        event.preventDefault()
        let error = false

        if (!name) {
            setNameError(true)
            error = true
        } else {
            setNameError(false)
        }
        if (!email) {
            setEmailError(true)
            error = true
        } else {
            setEmailError(false)
        }

        const body = {
            name,
            email,
            message
        }
        if (!error) {
            await axios.post(`${API_URL}/SendContactEmail`, body)
            setSubmitted(true)
        }
    }

    return (
        <Layout>
            <SEO title="Support" />
            <DiscoverMoreBanner/>
            <PurpleAccentHero
                desktopImage={data.desktopHeroImage.childImageSharp.fluid}
                mobileImage={data.mobileHeroImage.childImageSharp.fluid}
                alt="Man smiling in front of his computer in an open office environment"
            >
                <div className={classes.titleHeadingGradientWrapper}>
                    <section className={classes.titleHeadingText}>
                        <h1>Support</h1>
                    </section>
                </div>
            </PurpleAccentHero>
            <section className={classes.body}>
                <div className={classes.bodyContent}>
                    {!submitted ? (
                        <form className={classes.form} noValidate autoComplete="off">
                            <TextField
                                label="Name (Required)"
                                style={{ width: "100%", marginBottom: 38 }}
                                value={name}
                                error={nameError}
                                onChange={nameChange}
                                autoComplete="name"
                            />
                            <TextField
                                label="Email (Required)"
                                style={{ width: "100%", marginBottom: 38 }}
                                value={email}
                                error={emailError}
                                onChange={emailChange}
                                autoComplete="email"
                            />
                            <TextField
                                label="Anything else you need to tell us..."
                                multiline
                                rows="4"
                                style={{ width: "100%", marginBottom: 38 }}
                                value={message}
                                onChange={messageChange}
                            />
                            <Button style={{ width: 224, height: 36 }} onClick={onSubmit}>
                                Submit Request
                            </Button>
                        </form>
                    ) : (
                        <h3 className={classes.feedback}>Your support request has been received.</h3>
                    )}
                </div>
            </section>
            <section>
                <Banner
                    desktopImage={data.desktopBannerImage.childImageSharp.fluid}
                    mobileImage={data.mobileBannerImage.childImageSharp.fluid}
                    alt="People walking together in a crowd"
                />
            </section>
            <CookiesSnackbar />
        </Layout>
    )
}

export default RequestDemoPage
